import {User} from '@innobile/authmodule';

export class RcUser extends User {
  private readonly superAdmin: boolean;

  constructor(raw: any) {
    super(raw);
    this.superAdmin = raw.superAdmin;
  }

  get SuperAdmin(): boolean {
    return this.superAdmin;
  }
}
