import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService, LoggedInGuardService} from '@innobile/authmodule';
import {LoginComponent} from './login/login.component';

const routes: Routes = [{
  path: 'login',
  canActivate: [LoggedInGuardService],
  component: LoginComponent
}, {
  path: '',
  canLoad: [AuthGuardService],
  canActivate: [AuthGuardService],
  data: {breadcrumb: null},
  loadChildren: () => import('./main/main.module').then(m => m.MainModule)
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
