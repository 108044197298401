import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {environment} from '../environments/environment';
import {CustomAuthModule} from '@innobile/authmodule';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastInterceptor} from './toast.interceptor';
import {ProgressBarInterceptor} from './progress-bar.interceptor';
import {RcUser} from '../models/rc-user';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    CustomAuthModule.forRoot({
      config: {
        apiPrefix: environment.apiPrefix,
        userModel: RcUser,
        loginEndpoint: 'authenticate',
        whitelistedApiCalls: ['authenticate', 'users/reset']
      }
    }),
    ReactiveFormsModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ToastInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ProgressBarInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
