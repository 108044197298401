import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ToastElem} from '../models/toast-elem';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toasts: BehaviorSubject<ToastElem | null> = new BehaviorSubject<{ severity: string; message: string } | null>(null);

  constructor() {
  }

  public send(toastEl: ToastElem): void {
    this.toasts.next(toastEl);
  }

  public receive(): Observable<ToastElem | null> {
    return this.toasts.asObservable();
  }
}
