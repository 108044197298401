<main class="form-signin">
  <form (submit)="login()" [formGroup]="formGroup">
    <div class="title">
      <img src="../../../assets/logo.svg" alt="logo" width="64">
      <h4 class="mb-3">CD RedCross Admin</h4>
    </div>
    
    <div class="form-floating">
      <i class="material-symbols-outlined">account_circle</i>
      <input [class.invalid]="formGroup.controls.email.dirty && formGroup.controls.email.invalid"
        class="form-control" formControlName="email" placeholder="name@example.com"
        type="email">
    </div>
    <div class="form-floating">
      <i class="material-symbols-outlined">lock_open</i>
      <input [class.invalid]="formGroup.controls.password.dirty && formGroup.controls.password.invalid"
        class="form-control" formControlName="password" placeholder="Password" type="password">
    </div>

    <div [class.d-flex]="formGroup.hasError('form') && !this.formGroup.pristine" class="invalid-feedback">
      {{formGroup.getError('form') }}
    </div>

    <button [disabled]="formGroup.invalid" class="btn green" type="submit">
      <i class="material-symbols-outlined">login</i>
      Bejelentkezés
    </button>
  </form>
</main>